import React from 'react';

const Shower = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="inherit"
    height="inherit"
    viewBox="0 0 34.223 42.945"
  >
    <defs>
      <clipPath>
        <rect width="inherit" height="inherit" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(1 -3)">
      <g transform="translate(-1 3)" clipPath="url(#clip-path)">
        <path
          d="M21.273,11.6a8.229,8.229,0,0,0-16.222.384,11.7,11.7,0,0,0-.083,1.68Q4.96,26.8,4.959,39.928A4.772,4.772,0,0,1,4.832,41.2a2.455,2.455,0,0,1-4.775-.348,4.677,4.677,0,0,1-.051-.726q0-13.357,0-26.715a13.188,13.188,0,0,1,26.2-2.354.589.589,0,0,0,.544.557,7.048,7.048,0,0,1,5.457,5.806.749.749,0,0,0,.359.474,2.626,2.626,0,0,1,1.6,2.9,2.575,2.575,0,0,1-2.67,2.094q-7.411.01-14.821,0a2.57,2.57,0,0,1-2.641-2.064A2.6,2.6,0,0,1,15.672,17.9a.627.627,0,0,0,.339-.36A7.027,7.027,0,0,1,21.1,11.7a.934.934,0,0,0,.17-.107m3.359-.176a1.068,1.068,0,0,0,.015-.17A11.613,11.613,0,0,0,1.584,13.34q-.03,13.383,0,26.766a2.3,2.3,0,0,0,.061.61.818.818,0,0,0,.878.639.81.81,0,0,0,.826-.7,4.58,4.58,0,0,0,.039-.783q0-13.046,0-26.092c0-.43.01-.861.039-1.289A9.8,9.8,0,0,1,16.261,3.862a9.6,9.6,0,0,1,6.5,7.066c.048.18.2.457.325.471a15.062,15.062,0,0,0,1.548.022m-7.008,6.232H30.618A5.351,5.351,0,0,0,26.19,13.12a18.8,18.8,0,0,0-3.526-.094,5.412,5.412,0,0,0-5.04,4.627m6.441,3.666c2.489,0,4.977.005,7.466,0a1,1,0,0,0,1.105-1.006,1.034,1.034,0,0,0-1.057-1.016c-.13-.009-.262,0-.393,0q-7.073,0-14.146,0a2.73,2.73,0,0,0-.721.063.946.946,0,0,0-.526,1.544,1.591,1.591,0,0,0,1.03.4c2.413.034,4.827.018,7.241.017"
          transform="translate(0 0)"
          fill="currentColor"
        />
        <path
          d="M23.681,30.969a7.112,7.112,0,0,1-.007.9.735.735,0,0,1-.795.708.723.723,0,0,1-.754-.746c-.023-.56-.027-1.123,0-1.683a.709.709,0,0,1,.764-.73.73.73,0,0,1,.787.712,6.881,6.881,0,0,1,.006.841h0"
          transform="translate(-3.178 -4.229)"
          fill="currentColor"
        />
        <path
          d="M27.624,31.013c0-.281-.013-.562,0-.841a.728.728,0,0,1,.74-.759.746.746,0,0,1,.816.743,14.31,14.31,0,0,1,0,1.682.746.746,0,0,1-.769.741.772.772,0,0,1-.784-.781c-.016-.261,0-.523,0-.785"
          transform="translate(-3.971 -4.228)"
          fill="currentColor"
        />
        <path
          d="M33.13,30.99c0-.281-.015-.562,0-.841a.712.712,0,0,1,.706-.73.7.7,0,0,1,.831.652,10.719,10.719,0,0,1,0,1.846.7.7,0,0,1-.778.658.732.732,0,0,1-.757-.745c-.019-.279,0-.56,0-.841"
          transform="translate(-4.762 -4.229)"
          fill="currentColor"
        />
        <path
          d="M20.557,37.182c0,.262.01.524,0,.785a.736.736,0,0,1-.732.771.713.713,0,0,1-.812-.684,10.986,10.986,0,0,1,0-1.792.707.707,0,0,1,.809-.682.721.721,0,0,1,.736.761c.015.279,0,.56,0,.841"
          transform="translate(-2.728 -5.115)"
          fill="currentColor"
        />
        <path
          d="M26.062,37.178c0,.262.014.524,0,.785a.774.774,0,0,1-.786.779A.747.747,0,0,1,24.507,38a14.69,14.69,0,0,1,0-1.682.745.745,0,0,1,.818-.742.73.73,0,0,1,.738.76c.016.279,0,.56,0,.841"
          transform="translate(-3.519 -5.114)"
          fill="currentColor"
        />
        <path
          d="M31.565,37.148c0,.281.016.563,0,.841a.735.735,0,0,1-.756.748.719.719,0,0,1-.791-.708,12.255,12.255,0,0,1,0-1.737.777.777,0,0,1,1.55.015c.024.278,0,.56,0,.841"
          transform="translate(-4.31 -5.115)"
          fill="currentColor"
        />
        <path
          d="M37.068,37.182a7.645,7.645,0,0,1-.006.841.734.734,0,0,1-.785.716.711.711,0,0,1-.756-.685,11.713,11.713,0,0,1,0-1.793.706.706,0,0,1,.808-.681.714.714,0,0,1,.733.7c.029.3.005.6.005.9"
          transform="translate(-5.102 -5.115)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
);

export default Shower;
